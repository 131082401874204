<template>
  <div class="admin-dashboard">
    <AdminHeader />
    <main>
      <b-container>
        <div class="mt-5">
          <b-card>
            <b-card-body class="p-0">
              <b-card-title
                ><b-row>
                  <b-col>Manage Homepage</b-col>
                  <b-col class="text-right">
                    <b-button variant="primary" @click="addNew"
                      >Add Candidate</b-button
                    >
                  </b-col>
                </b-row>
              </b-card-title>
              <b-alert :show="!!errorMessage" variant="danger">{{
                errorMessage
              }}</b-alert>
              <div>
                <h4>Officers</h4>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Reorder</th>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Position</th>
                      <th scope="col">Type</th>
                      <th scope="col">Linked To</th>
                      <th scope="col">Headshot</th>
                      <th></th>
                    </tr>
                  </thead>
                  <draggable v-model="officers" tag="tbody">
                    <tr v-for="item in officers" :key="item.name">
                      <td scope="row">
                        <b-icon
                          icon="arrows-move"
                          aria-hidden="true"
                          :data-item-id="item.id"
                        ></b-icon>
                      </td>
                      <td>{{ item.order }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.position }}</td>
                      <td>
                        {{ item.useInternalAccount ? "Internal" : "External" }}
                      </td>
                      <td>
                        {{
                          item.useInternalAccount
                            ? item.internalAccountEmail
                            : item.externalUrl
                        }}
                      </td>
                      <td>
                        <b-img
                          rounded="circle"
                          height="50"
                          :src="item.headshotUrl"
                        ></b-img>
                      </td>
                      <td>
                        <b-button @click="edit(item)">Edit</b-button>
                      </td>
                    </tr>
                  </draggable>
                </table>
                <h4 class="mt-5">Board Members</h4>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Reorder</th>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Position</th>
                      <th scope="col">Type</th>
                      <th scope="col">Linked To</th>
                      <th scope="col">Headshot</th>
                      <th></th>
                    </tr>
                  </thead>
                  <draggable v-model="boardMembers" tag="tbody">
                    <tr v-for="item in boardMembers" :key="item.name">
                      <td scope="row">
                        <b-icon
                          icon="arrows-move"
                          aria-hidden="true"
                          :data-item-id="item.id"
                        ></b-icon>
                      </td>
                      <td>{{ item.order }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.position }}</td>
                      <td>
                        {{ item.useInternalAccount ? "Internal" : "External" }}
                      </td>
                      <td>
                        {{
                          item.useInternalAccount
                            ? item.internalAccountEmail
                            : item.externalUrl
                        }}
                      </td>
                      <td>
                        <b-img
                          rounded="circle"
                          height="50"
                          :src="item.headshotUrl"
                          alt="Image 2"
                        ></b-img>
                      </td>
                      <td>
                        <b-button @click="edit(item)">Edit</b-button>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </b-container>
    </main>
    <b-modal id="modal-homepage" :title="modalTitle" size="lg" hide-footer>
      <b-form @submit="onSubmit">
        <b-form-group label="Name">
          <b-form-input
            v-model="form.name"
            type="text"
            placeholder="Enter Name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Positions">
          <b-form-select
            v-model="form.position"
            :options="positions"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Account Type">
          <b-form-radio
            inline
            v-model="form.useInternalAccount"
            :value="Boolean(true)"
            >Internal</b-form-radio
          >
          <b-form-radio
            inline
            v-model="form.useInternalAccount"
            :value="Boolean(false)"
            >External</b-form-radio
          >
        </b-form-group>
        <b-form-group label="Linked Account" v-show="form.useInternalAccount">
          <b-form-select
            v-model="form.internalAccountId"
            :options="internalAccounts"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="External URL" v-show="!form.useInternalAccount">
          <b-form-input
            v-model="form.externalUrl"
            type="text"
            placeholder="Enter Website URL"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Headshot">
          <b-row>
            <b-col lg="9">
              <b-form-file
                v-model="form.headshot"
                accept="image/*"
                placeholder="Select a photo"
                @change.stop="imagePreview"
              ></b-form-file>
            </b-col>
            <b-col lg="3">
              <b-button @click="cropImage">Crop</b-button>
            </b-col>
          </b-row>
        </b-form-group>
        <b-row>
          <b-col>
            <cropper
              ref="cropper"
              class="cropper"
              :src="form.headshotUrl"
              :stencil-component="$options.components.CircleStencil"
              :stencil-props="{
                aspectRatio: 1,
                movable: true,
                resizable: true,
              }"
            />
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col class="text-left">
            <b-button
              v-if="!isNew"
              type="button"
              variant="danger"
              @click="onDelete"
              >Delete</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button type="submit" variant="primary">Submit</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import AdminHeader from "@/components/admin/Header.vue";
import draggable from "vuedraggable";
import { Cropper, CircleStencil } from "vue-advanced-cropper";
//import Stencil from "@/components/admin/Stencil.vue";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "ManageHomePage",
  components: {
    AdminHeader,
    draggable,
    Cropper,
    //  Stencil,
    CircleStencil,
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      isSaving: false,
      isNew: false,
      modalTitle: "Add New",
      dragging: false,
      form: {
        id: 0,
        name: "",
        order: 99,
        position: "",
        useInternalAccount: true,
        internalAccountId: "",
        headshot: null,
        headshotUrl: "",
        externalUrl: "",
        croppedImage: null,
      },
    };
  },
  computed: {
    positions() {
      return this.$store.state.settings.positions.map((x) => x.name);
    },
    internalAccounts() {
      return this.$store.state.settings.internalAccounts.map(
        ({ username, userId }) => ({ value: userId, text: username })
      );
    },
    boardMembers: {
      get() {
        return this.$store.state.settings.boardMembers;
      },
      set(value) {
        this.$store.dispatch("settings/updateBoardMembers", value);
      },
    },
    officers: {
      get() {
        return this.$store.state.settings.officers;
      },
      set(value) {
        this.$store.dispatch("settings/updateOfficers", value);
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("settings/getHomePageCandidates");
    await this.$store.dispatch("settings/getPositions");
    await this.$store.dispatch("settings/getInternalAccounts");
  },
  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.form.headshotUrl) {
      URL.revokeObjectURL(this.form.headshotUrl);
    }
  },
  methods: {
    addNew() {
      this.reset();
      this.isNew = true;
      this.modalTitle = "Add New";
      this.$bvModal.show("modal-homepage");
    },
    edit(item) {
      this.modalTitle = "Edit";
      this.isNew = false;
      this.$bvModal.show("modal-homepage");
      this.form.name = item.name;
      this.form.position = item.position;
      this.form.order = item.order;
      this.form.useInternalAccount = item.useInternalAccount;
      this.form.internalAccountId = item.internalAccountId;
      this.form.externalUrl = item.externalUrl;
      this.form.headshotUrl = item.headshotUrl;
      this.form.id = item.id;
      this.form.previewSrc = null;
    },
    cropImage() {
      const uploadedFile = this.form.headshot;
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (uploadedFile) {
            const file = new File([blob], uploadedFile.name, {
              type: uploadedFile.type,
              lastModified: new Date().getTime(),
            });
            this.form.headshot = file;
          }
          const url = URL.createObjectURL(blob);
          this.form.headshotUrl = url;
        });
      }
    },
    onChange({ coordinates, image }) {
      this.form.croppedImage = image;
    },
    imagePreview(e) {
      //   let reader = new FileReader();
      //   reader.onload = () => {
      //     this.form.previewSrc = reader.result;
      //   };
      //   reader.readAsDataURL(e.target.files[0]);

      /// Reference to the DOM input element
      const { files } = e.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.form.headshotUrl) {
          URL.revokeObjectURL(this.form.headshotUrl);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        this.form.headshotUrl = blob;
      }
    },
    async onDelete() {
      await this.$store.dispatch(
        "settings/deleteHomePageCandidate",
        this.form.id
      );
      await this.$store.dispatch("settings/getHomePageCandidates");
      this.$bvModal.hide("modal-homepage");
    },
    async onSubmit(event) {
      event.preventDefault();
      if (!this.form.internalAccountId) {
        this.form.internalAccountId = "";
      }
      await this.$store.dispatch("settings/saveHomePageCandidate", this.form);
      await this.$store.dispatch("settings/getHomePageCandidates");
      this.$bvModal.hide("modal-homepage");
    },
    reset() {
      this.form.position = "";
      this.form.name = "";
      this.form.useInternalAccount = true;
      this.form.internalAccountId = "";
      this.form.headshot = null;
      this.form.externalUrl = "";
      this.form.previewSrc = null;
      this.form.headshotUrl = "";
    },
  },
};
</script>
